import React from 'react';
import {Line} from 'react-chartjs-2';


export const  LineGraph = ({labels, data})=> {
    const state = {
        labels: labels,
        datasets: [
          {
            label: 'CO2 %',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(213,69,68, 1)',
            borderColor: 'rgba(213,69,68, 1)',
            borderWidth: 2,
            data: data
          }
        ]
    }
    return (
      <div className="graph">
        <Line
          data={state}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    );
        }
