import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import {LineGraph}  from "./components/line-graph/linegraph.component";
import {data} from "./data/result";
import DatePicker from './components/datepicker/datepicker.component';


class App extends React.Component {
  constructor(){
        super();
        this.state = {
          data: null,
          bornPPM: null,
          currPPM: null,
          incrementPPM: null,
          dob: "2012-11-15",
          dataLabels: null
        }
  }

  handleInputChange=(date)=> {
    this.setState({dob: this.formatDate(date) }, ()=>{
          console.log(this.state)
      });
    }

  formatDate = (date) => {	// formats a JS date to 'yyyy-mm-dd'
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
    
      return [year, month, day].join('-');
    }
  
  
  dateRange = (startDate, endDate)=>{
      var start      = startDate.split('-');
      var end        = endDate.split('-');
      var startYear  = parseInt(start[0]);
      var endYear    = parseInt(end[0]);
      var Month = parseInt(start[1])
      var dates      = [];
      
      for(var i = startYear; i < endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1])-1 : 0;
        // for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        //   var month = j+1;
        //   var displayMonth = month < 10 ? +month : month;
          dates.push([i, Month].join('-'));
        // }
      }
      dates.push("2021-4")
      console.log(dates)
      return dates;
    }

  findData = ()=>{

    const dob = this.state.dob.split("-")[0]+"-"+String(parseInt(this.state.dob.split("-")[1]));
    const bornPPM = data[dob];
    const date = "2021-4";
    const currPPM = data[date];
    let incrementPPM = parseFloat((((currPPM/bornPPM)-1)*100).toFixed(3));
    let labels = this.dateRange(dob, date);
    let Co2data = []
    for(let index=0; index<labels.length; index++){
      Co2data.push(data[labels[index]]);
    }
    this.setState({bornPPM: bornPPM, currPPM: currPPM, dataLabels: labels, incrementPPM: incrementPPM, data: Co2data});
   
  }

  // componentDidMount(){
  //   this.findData();
  // }
  shouldComponentUpdate(newProps, newState) {
    if(newState["bornPPM"] !== this.state["bornPPM"])
      return true;
    else 
      return false;
 }


  render(){
      return (
        <div class="bg-cover">
         <div className="content">
        <div class="container">
        <div>
            <center>
              <h2  className="title">What was the CO<sub>2</sub> when you were born?</h2>
            </center>
          </div>
          <div class="form-position">
            <div class="card">
              <div class="card-header">
                <h3>Enter your date of birth</h3>

              </div>
              <div class="card-body">
                <center>
                  {/* <div class="input-group form-group date-input">
                    <input type="date" class="form-control" onChange={this.handleInputChange} />
                  </div> */}
                  <DatePicker handleDatePick={this.handleInputChange}/>
                </center>
                  <center>
                    <div class="form-group">
                      <br/>
                      <button  class="btn float-right login_btn" onClick={this.findData}>Check CO<sub>2</sub> Level</button>
                    </div>
                  </center>
              </div>
              {/* <canvas id="buyers" width="600" height="400"></canvas> */}
            </div>
            { this.state.data ?
            <div>
               <div className="result top">
                <center><p>You were born at <u>{this.state.bornPPM} PPM </u>CO<sub>2</sub>. It is now <u>{this.state.currPPM} PPM</u>. Since you were born, the Atmospheric CO<sub>2</sub> has increased by <u>{this.state.incrementPPM}%</u></p></center>
            </div>
            <center>
            <LineGraph labels={this.state.dataLabels} data={this.state.data}/>
            </center>   
           
            <div className="result middle">
              <div class="row">
                <div class="col-4">
                  Born at <u>{this.state.bornPPM} ppm</u>
                </div>
                <div class="col-4">
                  Current <u>{this.state.currPPM} ppm</u>
                </div>
                <div class="col-4">
                  Increased <u>{this.state.incrementPPM}%</u>
                </div>
              </div>
            </div>
            
            </div> : null}
            <div className="result sample">
              <center><h3>Did you know ?</h3></center>
              <br/>
              <ul>
              <li> Maddona was born at <u>314.93 CO<sub>2</sub> PPM</u>, the atmospheric CO<sub>2</sub> has increased by  <u>33.061%</u> since then.</li>
              <li> Obama was born at <u>316.79  CO<sub>2</sub> PPM</u>, the atmospheric CO<sub>2</sub> has increased by <u>32.28%</u> since then.</li>
              <li> Greta was born at <u>375.07  CO<sub>2</sub> PPM</u>, the atmospheric CO<sub>2</sub> has increased by <u>11.726%</u> since then.</li>
              <li> Lady Gaga was born at <u>348.25 CO<sub>2</sub> PPM</u>, the atmospheric CO<sub>2</sub> has increased by <u>20.33%</u> since then.</li>
              <li> When the TV Series Friends ended the atmospheric CO<sub>2</sub> was <u>380.8 PPM</u>, it has changed by <u>10.045%</u> since then.</li>
              <li> When Berlin wall fell the atmospheric CO<sub>2</sub> was <u>351.55 PPM</u>, it has changed by <u>19.201% </u>since then.</li>
              <li> When Neil Armstrong landed on moon the atmospheric CO<sub>2</sub> was <u>325.88 PPM</u>, it has changed by <u>28.59%</u> since then.</li>
              </ul>

          </div>

        </div>
        </div>
        </div>
        <footer>
          <center>
          <p className="footer">Data from <a href="https://gml.noaa.gov/ccgg/trends/" target="_blank" >NOAA</a></p>
          </center>
        </footer>
        </div>
      
      );
      }
}

export default App;
